#addressBookWidgetDiv,
#walletWidgetDiv {
    width: 100%;
    height: 100%;
}
.apa .amazon-widget {
    display: block;
    height: 300px;
    clear: none;
}
.apa .section {
    display: block;
    clear: none;
}
@media only screen and (min-width: 575px) {
    .apa #apa-shipping {
        width: 260px;
        margin-right: 15px;
        float: left;
    }
    .apa #apa-payment {
        margin-left: 275px;
    }
    .apa-virtual-checkout #apa-payment {
        margin-left: 0 !important;
    }
}
@media only screen and (min-width: 775px) {
    .apa #apa-shipping {
        width: 360px;
        margin-right: 15px;
        float: left;
    }
    .apa #apa-payment {
        margin-left: 375px;
    }
}
@media only screen and (min-width: 960px) {
    .apa #apa-shipping {
        width: 310px;
        margin-right: 15px;
        float: left;
    }
    .apa #apa-payment {
        width: 310px;
        margin-right: 15px;
        margin-left: 0;
        float: left;
    }
    .apa .narrow-section {
        height: 298px;
        margin-left: 650px;
        clear: none;
        overflow: auto;
    }
    .apa-virtual-checkout #apa-payment {
        width: 360px;
        float: left;
        margin-right: 15px;
    }
    .apa-virtual-checkout .review-section {
        margin-left: 375px;
        height: 298px;
        clear: none;
        overflow: auto;
    }
}
